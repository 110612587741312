// Cores
$blue-marine: #24344b;
$blue-default: #0072bc;
$blue-light: #68b8eb;
$blue-dark: #1193e7;
$blue-sea: #015a94;
$gray: #656a72;
$gray-light: #656a72;
$brown-light: #726567;
$green-olive: #00babc;

.blue-marine {
  color: $blue-marine!important;
}

.gray {
  color: $gray!important;
}
