@import url('https://themes.googleusercontent.com/fonts/css?kit=Lx1xfUTR4qFjwg0Z_pb902yWOW57Glq29V3__n4AGA7eUTbR4W0XOgE2iH4kL8Gh');
.lst-kix_ngs1h2ka0urd-8>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-2,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-3,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-4,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-5,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-6,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-7,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-8,decimal) ". "
}

ol.lst-kix_list_1-3{
    list-style-type:none
}

ol.lst-kix_list_1-4{
    list-style-type:none
}

ol.lst-kix_list_1-5{
    list-style-type:none
}

ol.lst-kix_list_1-6{
    list-style-type:none
}

.lst-kix_ngs1h2ka0urd-6>li:before{
  content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-2,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-3,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-4,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-5,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-6,decimal) ". "
}
ol.lst-kix_list_1-0{
  list-style-type:none
}
.lst-kix_ngs1h2ka0urd-7>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-2,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-3,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-4,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-5,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-6,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-7,decimal) ". "
}
ol.lst-kix_list_1-1{
    list-style-type:none
}
ol.lst-kix_list_1-2{
    list-style-type:none
}
ol.lst-kix_ngs1h2ka0urd-5.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-5 0
}
.lst-kix_list_1-1>li{
    counter-increment:lst-ctn-kix_list_1-1
}
ol.lst-kix_ngs1h2ka0urd-1.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-1 0
}
.lst-kix_ngs1h2ka0urd-1>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) ". "
}
.lst-kix_ngs1h2ka0urd-0>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) ". "
}
.lst-kix_ngs1h2ka0urd-3>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-3
}
ol.lst-kix_list_1-8.start{
    counter-reset:lst-ctn-kix_list_1-8 0
}
ol.lst-kix_ngs1h2ka0urd-4.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-4 0
}
ol.lst-kix_list_1-5.start{
    counter-reset:lst-ctn-kix_list_1-5 0
}
ol.lst-kix_list_1-7{
    list-style-type:none
}
.lst-kix_list_1-7>li{
    counter-increment:lst-ctn-kix_list_1-7
}
ol.lst-kix_list_1-8{
    list-style-type:none
}
.lst-kix_ngs1h2ka0urd-5>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-5
}
.lst-kix_ngs1h2ka0urd-8>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-8
}
.lst-kix_ngs1h2ka0urd-2>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-2
}
ol.lst-kix_list_1-7.start{
    counter-reset:lst-ctn-kix_list_1-7 0
}
ol.lst-kix_ngs1h2ka0urd-0.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-0 0
}
.lst-kix_list_1-2>li{
    counter-increment:lst-ctn-kix_list_1-2
}
ol.lst-kix_ngs1h2ka0urd-6.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-6 0
}
.lst-kix_list_1-5>li{
    counter-increment:lst-ctn-kix_list_1-5
}
.lst-kix_ngs1h2ka0urd-2>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-2,decimal) ". "
}
.lst-kix_list_1-8>li{
    counter-increment:lst-ctn-kix_list_1-8
}
.lst-kix_ngs1h2ka0urd-3>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-2,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-3,decimal) ". "
}
.lst-kix_ngs1h2ka0urd-4>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-2,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-3,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-4,decimal) ". "
}
ol.lst-kix_list_1-4.start{
    counter-reset:lst-ctn-kix_list_1-4 0
}
ol.lst-kix_list_1-1.start{
    counter-reset:lst-ctn-kix_list_1-1 0
}
.lst-kix_ngs1h2ka0urd-5>li:before{
    content:"" counter(lst-ctn-kix_ngs1h2ka0urd-0,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-1,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-2,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-3,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-4,decimal) "." counter(lst-ctn-kix_ngs1h2ka0urd-5,decimal) ". "
}
ol.lst-kix_ngs1h2ka0urd-3.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-3 0
}
.lst-kix_list_1-4>li{
    counter-increment:lst-ctn-kix_list_1-4
}
ol.lst-kix_list_1-6.start{
    counter-reset:lst-ctn-kix_list_1-6 0
}
.lst-kix_ngs1h2ka0urd-7>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-7
}
ol.lst-kix_list_1-3.start{
    counter-reset:lst-ctn-kix_list_1-3 0
}
ol.lst-kix_list_1-2.start{
    counter-reset:lst-ctn-kix_list_1-2 0
}
ol.lst-kix_ngs1h2ka0urd-0{
    list-style-type:none
}
ol.lst-kix_ngs1h2ka0urd-7.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-7 0
}
.lst-kix_ngs1h2ka0urd-0>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-0
}
ol.lst-kix_ngs1h2ka0urd-3{
    list-style-type:none
}
ol.lst-kix_ngs1h2ka0urd-4{
    list-style-type:none
}
.lst-kix_list_1-0>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) ". "
}
ol.lst-kix_ngs1h2ka0urd-1{
    list-style-type:none
}
ol.lst-kix_ngs1h2ka0urd-2{
    list-style-type:none
}
ol.lst-kix_ngs1h2ka0urd-7{
    list-style-type:none
}
ol.lst-kix_ngs1h2ka0urd-8{
    list-style-type:none
}
.lst-kix_list_1-1>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) ". "
}
.lst-kix_list_1-2>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) "." counter(lst-ctn-kix_list_1-2,decimal) ". "
}
ol.lst-kix_ngs1h2ka0urd-5{
    list-style-type:none
}
ol.lst-kix_ngs1h2ka0urd-6{
    list-style-type:none
}
.lst-kix_ngs1h2ka0urd-6>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-6
}
.lst-kix_list_1-3>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) "." counter(lst-ctn-kix_list_1-2,decimal) "." counter(lst-ctn-kix_list_1-3,decimal) ". "
}
.lst-kix_list_1-4>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) "." counter(lst-ctn-kix_list_1-2,decimal) "." counter(lst-ctn-kix_list_1-3,decimal) "." counter(lst-ctn-kix_list_1-4,decimal) ". "
}
ol.lst-kix_list_1-0.start{
    counter-reset:lst-ctn-kix_list_1-0 0
}
.lst-kix_list_1-0>li{
    counter-increment:lst-ctn-kix_list_1-0
}
ol.lst-kix_ngs1h2ka0urd-2.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-2 0
}
.lst-kix_list_1-6>li{
    counter-increment:lst-ctn-kix_list_1-6
}
.lst-kix_list_1-7>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) "." counter(lst-ctn-kix_list_1-2,decimal) "." counter(lst-ctn-kix_list_1-3,decimal) "." counter(lst-ctn-kix_list_1-4,decimal) "." counter(lst-ctn-kix_list_1-5,decimal) "." counter(lst-ctn-kix_list_1-6,decimal) "." counter(lst-ctn-kix_list_1-7,decimal) ". "
}
.lst-kix_list_1-3>li{
    counter-increment:lst-ctn-kix_list_1-3
}
.lst-kix_list_1-5>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) "." counter(lst-ctn-kix_list_1-2,decimal) "." counter(lst-ctn-kix_list_1-3,decimal) "." counter(lst-ctn-kix_list_1-4,decimal) "." counter(lst-ctn-kix_list_1-5,decimal) ". "
}
.lst-kix_list_1-6>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) "." counter(lst-ctn-kix_list_1-2,decimal) "." counter(lst-ctn-kix_list_1-3,decimal) "." counter(lst-ctn-kix_list_1-4,decimal) "." counter(lst-ctn-kix_list_1-5,decimal) "." counter(lst-ctn-kix_list_1-6,decimal) ". "
}
.lst-kix_ngs1h2ka0urd-4>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-4
}
.lst-kix_ngs1h2ka0urd-1>li{
    counter-increment:lst-ctn-kix_ngs1h2ka0urd-1
}
.lst-kix_list_1-8>li:before{
    content:"" counter(lst-ctn-kix_list_1-0,decimal) "." counter(lst-ctn-kix_list_1-1,decimal) "." counter(lst-ctn-kix_list_1-2,decimal) "." counter(lst-ctn-kix_list_1-3,decimal) "." counter(lst-ctn-kix_list_1-4,decimal) "." counter(lst-ctn-kix_list_1-5,decimal) "." counter(lst-ctn-kix_list_1-6,decimal) "." counter(lst-ctn-kix_list_1-7,decimal) "." counter(lst-ctn-kix_list_1-8,decimal) ". "
}
ol.lst-kix_ngs1h2ka0urd-8.start{
    counter-reset:lst-ctn-kix_ngs1h2ka0urd-8 0
}
ol{
    margin:0;
    padding:0
}
.c0{
    font-weight:700;
    text-decoration:none;
    vertical-align:baseline;
    font-size:1.8em;
    font-family:$primary-font;
    color:#656a72;
    font-style:normal;
}
.c2{
    line-height:1.5;
    orphans:2;
    widows:2;
    text-align:justify
}
.c3{
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-size: 0.95em;
    font-family:$primary-font;
    font-style:normal
}
.c4{
    margin-left:3em;
    font-size: 0.95em;
    font-weight:400;
    font-family:$primary-font;
    padding-top:0.41666666666em;
    padding-left:0;
    padding-bottom:0;
    line-height:1.5;
    orphans:2;
    widows:2;
    text-align:justify;
}
.c5{
    padding-top:0.83333333333em;
    padding-bottom:0.83333333333em;
    line-height:1.5;
    orphans:2;
    widows:2;
    text-align:center
}
.c6{
    font-family:$primary-font;
    font-weight:700;
    color: #656a72;
}
.c7{
    font-weight:400;
    text-decoration:none;
    vertical-align:baseline;
    font-size: 1em;
    font-family:$primary-font;
    font-style:normal
}
.c8{
    margin-left:0;
    padding-top:0;
    padding-left:0;
    padding-bottom:0;
    line-height:1.5;
    orphans:2;
    widows:2;
    text-align:justify;
}
.c9{
    margin-left:6em;
    padding-top:0;
    padding-left:0;
    padding-bottom:0;
    line-height:1.5;
    orphans:2;
    widows:2;
    text-align:justify
}
.c10{
    padding:0;
    margin:0
}
.c11{
    color:inherit;
    text-decoration:inherit
}
.c13{
    font-size: 1em;
    font-family:$primary-font;
    font-weight:400
}
.c17{
    margin-left:3em;
    padding-top:0;
    padding-bottom:0;
    line-height:1.5;
    orphans:2;
    widows:2;
    text-align:justify
}
.link{
    -webkit-text-decoration-skip:none;
    color:#1155cc;
    font-weight:400;
    text-decoration:underline;
    text-decoration-skip-ink:none;
    font-size: 1em;
    font-family:$primary-font;
}
@media only screen and (min-width: 300px) {
  /* For mobile phones: */
  .container{
      max-width:66.7083333333em;
      padding:5em 2em 2em 2em;
      margin: 0 auto;
  }
  .c4{
      margin-left:1em;
  }
  .c6{
      font-size:1.1em;
  }
  .c9{
      margin-left:2em;
  }
  .c17{
      margin-left:1em;
  }
}
@media only screen and (min-width: 600px) {
  /* For tablets and desktops: */
  .container{
      max-width:66.7083333333em;
      padding:6em 0 0 0;
      margin: 0 auto;
  }
  .c6{
      font-size:1.5em;
  }
}
.info-block-text p {
  font-family: $primary-font;
  color: $white;
  font-size: rem-calc(13);
  font-weight: 500;
  margin-bottom: 5px;
}
.definition li {
  list-style-type: lower-latin;
}
