// Header
.top-bar {
  background: $white;
  padding: 0;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.04);
  position: fixed;
  z-index: 9;
  width: 100%;

  .grid-container {
    width: 100%;
  }

  .logo {
    img {
      max-width: 180px;
      transition: all .2s ease-in-out;
    }

    &:hover {
      img {
        opacity: 0.6;
      }
    }
  }

  .menu {
    background: none;

    @include breakpoint(small down) {
        display: block;
        margin: 1em 0;

        li a {
          padding: 1em 2.5em!important;
        }
    }

    li a {
      font-family: $primary-font;
      font-size: rem-calc(11);
      padding: 3em 2.5em;
      font-weight: bold;
      color: $blue-marine;
      text-transform: uppercase;
      border-top: 2px solid transparent;
      transition: all .2s ease-in-out;

      &:hover {
        color: $blue-default;
        background: #f8f8f8;
        border-color: $blue-default;
      }
    }

    .login {
      display: table!important;
      letter-spacing: 0.2px;
    }
  }

  .login {
    margin-top: 1.7em;
    margin-left: 1em;
  }
}
