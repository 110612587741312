input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #656565;
  opacity: 1; /* Firefox */
}
input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #656565;
}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #656565;
  opacity: 1;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #656565;
  opacity: 1;
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #656565;
}
input::-ms-input-placeholder { /* Microsoft Edge */
  color: #656565;
}

.dashboard {
  &-grid {
    height: 100vh;

    .logo {
      width: 100%;

      img {
        width: 175px;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

.login {
  max-width: 420px;
  margin: 2.5em auto;
  border: 1px solid #c6eaf7;
  padding: 2.5em 0;

  &-title {
    font-family: $primary-font;
    font-weight: 600;
    text-align: center;
    color: #24344b;
    padding-bottom: 0.7em;
    font-size: rem-calc(18);
  }

  input[type='text'], input[type='email'], input[type='tel'], input[type='password'] {
    border-color: #c6eaf7;
    transition: all .2s ease-in-out;
    max-width: 380px;
    box-shadow: none!important;

    &:focus {
      border-color: #0072bc;
      border-left: 3px solid #0072bc;
    }
  }
  
  .input-group-label {
    margin-top: 0;
  }

  .submit {
    width: 100%;
    display: block;
    margin-top: 2em;
    font-size: rem-calc(13);
    background: linear-gradient(to right, #0173bc 0%,#48a6e2 50%);
    transition: all .3s linear;

    &:hover {
      opacity: 0.8;
      box-shadow: 0px 19px 91px -6px rgba(71,166,225,1);
    }
  }

  legend {
    font-family: $primary-font;
    font-size: rem-calc(13);
    color: #b7b7b7;
  }

  a {
    font-family: $primary-font;
    font-size: rem-calc(13);
    color: #0072bc;
    text-decoration: underline;
    margin-left: 0.5em;
  }
}

.back {
  font-family: $primary-font;
  font-size: rem-calc(13);
  color: #5f616a!important;
  text-align: center;
  margin: 0 auto;
  display: block;
  text-decoration: underline;
}
