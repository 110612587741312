.hero {
  &-content {
    @include xy-grid-container;
    @include xy-grid;
    height: 100%;
  }

  &-block {
    width: 100%;
    @include flex;
    @include flex-align(center, middle);

    .btn-group {
      display: table;
      margin: 0 auto;
    }

    .btn {
      padding: 1.60em 2em!important;
      text-align:center;
      border-radius: 30px;
      width: 210px;
      margin: 1.5em 1em;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &.gradient-blue {
        background: linear-gradient(to bottom, #2370a2 0%,#025b94 100%);
      }

      &.gradient-blue-light {
        background: linear-gradient(to bottom, #61b2e6 0%,#4aa6e2 100%);
      }

      &:hover {
        opacity: .85;
      }

      @include breakpoint(small down) {
        display: block;
      }
    }
  }

  &-title {
    h2 {
      font-family: $secondary-font;
      font-size: rem-calc(110);
      color: $blue-default;

      @include breakpoint(small down) {
          font-size: rem-calc(70);
          line-height: 1.3;
      }
    }

    h3 {
      font-family: $primary-font;
      text-transform: uppercase;
      font-size: rem-calc(14);
      line-height: 2;
      font-weight: 600;
      letter-spacing: 2px;
      max-width: rem-calc(600);
      color: $blue-default;
      margin: 1em auto;
    }

    h2, h3 {
      display: block;
      text-align: center;
    }
  }
}
